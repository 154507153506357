<template>
  <r-e-dialog
      title="税率设置"
      class="layer-contract-detail"
      :visible.sync="addDialogVisible"
      show-footer
      top="10vh"
      width="400px"
      @click-submit="clickSubmit"
      @click-cancel="clickCancel"
      @close="clickCancel"
  >

    <!--表单-->
    <el-form :rules="rules" ref="ruleForm" :model="formdata" label-width="80px" size="mini">
      <el-form-item label="商品名称" prop="productName">
        <el-input  v-model="formdata.productName"></el-input>
      </el-form-item>
      <el-form-item label="商品编码" prop="commodityCode" >
        <el-input  v-model="formdata.commodityCode"></el-input>
      </el-form-item>
      <el-form-item label="房租"  prop="rentTax">
        <el-input  v-model="formdata.rentTax">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="电费"  prop="electricityTax">
        <el-input  v-model="formdata.electricityTax">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="自来水"  prop="waterTax">
        <el-input  v-model="formdata.waterTax">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="污水"  prop="sewageTax">
        <el-input  v-model="formdata.sewageTax">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="服务费"  prop="serviceTax">
        <el-input  v-model="formdata.serviceTax">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
    </el-form>

  </r-e-dialog>
</template>

<script>
import {validateNumber1,validateNumber2,validateNumber3,validateNumber4,validateNumber5} from "@/views/bill-management/payment-record/data";
import {paybillapi, paybillapihuixian} from "@/api/bill";
import el from "element-ui/src/locale/lang/el";

export default {
  name: "dialog-add-invoice",
  data() {
    return {
      addDialogVisible: false,
      formdata:{
        productName:null,//商品名称
        commodityCode:null,//商品编码
        rentTax:null,//房租
        electricityTax:null,//电费
        waterTax:null,//自来水
        sewageTax:null,//污水
        serviceTax:null,//服务费
      },
      rules: {
        productName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ],
        commodityCode: [
          { required: true, message: '请输入商品编码', trigger: 'blur' },
        ],
        rentTax: [
          { required: true, message: '请添加房租 ', trigger: 'blur' },
          { validator: validateNumber1,  trigger: 'blur' },
        ],
        electricityTax: [
          { required: true, message: '请输入电费', trigger: 'blur' },
          { validator: validateNumber2,  trigger: 'blur' },
        ],
        waterTax: [
          { required: true, message: '请输入自来水', trigger: 'blur' },
          { validator: validateNumber3,  trigger: 'blur' },
        ],
        sewageTax: [
          { required: true, message: '请添加污水 ', trigger: 'blur' },
          { validator: validateNumber4,  trigger: 'blur' },
        ],
        serviceTax: [
          { required: true, message: '请添加服务费', trigger: 'blur' },
          { validator: validateNumber5,  trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    this.paybillapihuixian()
  },
  methods:{
    // 获取回显信息
    paybillapihuixian(){
      paybillapihuixian().then(res => {
        let {info} = res
        if(info.productName){
          this.formdata = info
        }
      })
    },
    // 打开弹框
    openinvoiceclick(){
      this.addDialogVisible = true
    },

    // 确认按钮
     clickSubmit(){
      let itr  = true
      this.$refs.ruleForm.validate((valid) => {
        console.log(valid)
        if (valid) {
          // alert('99999')
        } else {
          console.log('error submit!!');
          itr = false
          return false;
        }
      });
       // 校验   //服务费
       if(this.formdata.serviceTax){
         let r  = this.formdata.serviceTax
         let fern = true
         if(!/^\d+$/.test(r) ) fern= false
         let  a = Number(this.formdata.serviceTax)
         itr = a >= 0 && a <= 100 && itr && fern;
       }
       //污水
       if(this.formdata.sewageTax){
           let r  = this.formdata.serviceTax
         let fern = true
         if(!/^\d+$/.test(r) ) fern= false
         let a = Number(this.formdata.sewageTax)
         itr = a >= 0 && a <= 100  && itr && fern;
       }
       //自来水
       if(this.formdata.waterTax){
           let r  = this.formdata.serviceTax
         let fern = true
         if(!/^\d+$/.test(r) ) fern= false
         let a = Number(this.formdata.waterTax)
         itr = a >= 0 && a <= 100 && itr && fern;
       }
       //电费
       if(this.formdata.electricityTax){
           let r  = this.formdata.serviceTax
         let fern = true
         if(!/^\d+$/.test(r) ) fern= false
         let a = Number(this.formdata.electricityTax)
         itr = a >= 0 && a <= 100 && itr && fern;
       }
       //房租
       if(this.formdata.rentTax){
           let r  = this.formdata.serviceTax
         let fern = true
         if(!/^\d+$/.test(r) ) fern= false
         let a = Number(this.formdata.rentTax)
         itr = a >= 0 && a <= 100 && itr && fern;
       }
       console.log(itr,this.formdata.serviceTax,)
      if(itr !== false){
            paybillapi(this.formdata).then(res => {
              this.addDialogVisible = false
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
            })
        itr  = true
      }
    },
    // 关闭弹框
    clickCancel(){
      this.addDialogVisible = false
      this.formdata =  {
        productName:null,//商品名称
        commodityCode:null,//商品编码
        rentTax:null,//房租
        electricityTax:null,//电费
        waterTax:null,//自来水
        sewageTax:null,//污水
        serviceTax:null,//服务费
      }
    },

  }
}
</script>

<style scoped lang="scss">

</style>